import axios from 'axios';
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalPages: 0,
  currentPage: 1,
  pageSize: 30,
  leadList: [],
  leadLoading: false,
  isLeadCreated: false,
  leadDetails: {},
  totolOfLead: 0,
  count: {},
  leadAddress: {},
  addressLoading: false,
};

export const createLeadDetails = createAsyncThunk(
  'lead/add-lead',
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`lead/add-lead`, props)
        .then(res => {
          if (res?.data?.err === 0) {
            toast.success(res.data?.msg);
            resolve(res.data.data);
          } else {
            toast.error(res?.data?.msg);
            reject({ message: res?.data?.msg });
          }
        })
        .catch(error => {
          toast.error(error?.response?.data?.msg);
          reject(error);
        });
    });
  },
);
export const getLeadData = createAsyncThunk(
  'lead/list-lead',
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const { start, limit, searchData } = props;
      axios
        .post(`lead/list-lead/${start}/${limit}`, searchData)
        .then(res => {
          if (res?.data?.err === 0) {
            if (Object.keys(res?.data?.data)?.length > 0) {
              resolve(res?.data?.data);
            } else {
              resolve({});
            }
          } else {
            reject({});
            // toast.error(res.data.msg);
          }
        })
        .catch(error => {
          toast.error(error?.response?.data?.msg);
          reject(error);
        });
    });
  },
);

export const exportLeadData = createAsyncThunk(
  'export-lead-data',
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`lead/list-lead/0/0`, props)
        .then(res => {
          if (res?.data?.err === 0) {
            window.open(res?.data?.data, '_blank');
            resolve({});
          } else {
            reject({ message: res?.data?.msg });
            toast.error(res.data.msg);
          }
        })
        .catch(error => {
          toast.error(error?.response?.data?.msg);
          reject(error);
        });
    });
  },
);

export const updateLeadData = createAsyncThunk(
  'lead/update-lead',
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`lead/update-lead`, props)
        .then(res => {
          if (res?.data?.err === 0) {
            toast.success(res.data?.msg);
            resolve(res.data?.data);
          } else {
            toast.error(res?.data?.msg);
            reject({ message: res?.data?.msg });
          }
        })
        .catch(error => {
          toast.error(error?.response?.data?.msg);
          reject(error);
        });
    });
  },
);
export const viewLeadData = createAsyncThunk(
  'lead/view-lead',
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`lead/view-lead`, props)
        .then(res => {
          if (res?.data?.err === 0) {
            if (Object.keys(res?.data?.data).length > 0) {
              resolve(res?.data?.data);
            } else {
              resolve({});
            }
          } else {
            reject({});
            // toast.error(res.data.msg);
          }
        })
        .catch(error => {
          toast.error(error.response.data.msg);
          reject(error);
        });
    });
  },
);
export const deleteLeadData = createAsyncThunk(
  'lead/delete-lead',
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`lead/delete-lead`, props)
        .then(res => {
          if (res?.data?.err === 0) {
            toast.success(res.data?.msg);
            resolve(res.data?.data);
          } else {
            toast.error(res?.data?.msg);
            reject({ message: res?.data?.msg });
          }
        })
        .catch(error => {
          toast.error(error?.response?.data?.msg);
          reject(error);
        });
    });
  },
);

export const getAddress = createAsyncThunk(
  'lead/list-lead/pincode',
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`lead/list-lead/pincode`, props)
        .then(res => {
          if (res?.data?.err === 0) {
            if (Object.keys(res?.data?.data)?.length > 0) {
              resolve(res?.data?.data);
            } else {
              resolve({});
            }
          } else {
            reject({ message: res?.data?.msg });
            toast.error(res.data.msg);
          }
        })
        .catch(error => {
          toast.error(error?.response?.data?.msg);
          reject(error);
        });
    });
  },
);

export const lead = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setLeadCreated: (state, action) => {
      state.isLeadCreated = action.payload;
    },
    setLeadDetail: (state, action) => {
      state.leadDetails = action.payload;
    },
    setLeadAddress: (state, action) => {
      state.leadAddress = action.payload;
    },
    setIsLeadUpdated: (state, action) => {
      state.isLeadUpdated = action.payload;
    },
    setTotolOfUser: (state, action) => {
      state.totolOfUser = action.payload;
    },
  },
  extraReducers: {
    [createLeadDetails.pending]: state => {
      state.isLeadCreated = false;
      state.leadLoading = true;
    },
    [createLeadDetails.rejected]: state => {
      state.isLeadCreated = false;
      state.leadLoading = false;
    },
    [createLeadDetails.fulfilled]: (state, action) => {
      state.isLeadCreated = true;
      state.leadLoading = false;
    },

    [getLeadData.pending]: state => {
      state.leadList = [];
      state.leadLoading = true;
    },
    [getLeadData.rejected]: state => {
      state.leadDetails = [];
      state.leadLoading = false;
    },
    [getLeadData.fulfilled]: (state, action) => {
      let leadListData = [];
      let leadListTotal = 0;
      let counts = {};
      let totalPageData = 0;
      if (action?.payload?.leads?.length > 0) {
        let {
          hysteroscopy_count,
          investigation_of_female_count,
          investigation_of_male_count,
          opu_count,
          semen_analysis_count,
          stimulation_started_count,
        } = action.payload.counts[0];
        leadListData = action.payload.leads;
        leadListTotal = action.payload.total_lead;
        totalPageData = Math.ceil(action?.payload?.total_lead / state.pageSize);
        counts = {
          action: action?.payload?.total_lead,
          hysteroscopy: hysteroscopy_count,
          investigation_of_female: investigation_of_female_count,
          investigation_of_male: investigation_of_male_count,
          opu: opu_count,
          semen_analysis: semen_analysis_count,
          stimulation_started: stimulation_started_count,
        };
      }
      state.totalPages = totalPageData;
      state.leadList = leadListData;
      state.totolOfLead = leadListTotal;
      state.count = counts;
      state.leadLoading = false;
    },
    [updateLeadData.pending]: state => {
      state.isLeadCreated = true;
      state.leadLoading = true;
    },
    [updateLeadData.rejected]: state => {
      state.isLeadCreated = false;
      state.leadLoading = false;
    },
    [updateLeadData.fulfilled]: state => {
      state.isLeadCreated = true;
      state.leadLoading = false;
      state.leadDetail = {};
    },
    [viewLeadData.pending]: state => {
      state.leadDetails = {};
      state.leadLoading = true;
    },
    [viewLeadData.rejected]: state => {
      state.leadDetails = {};
      state.leadLoading = false;
    },
    [viewLeadData.fulfilled]: (state, action) => {
      state.leadDetails = action.payload || {};
      state.leadLoading = false;
    },
    [exportLeadData.pending]: state => {
      state.leadLoading = true;
    },
    [exportLeadData.rejected]: state => {
      state.leadLoading = false;
    },
    [exportLeadData.fulfilled]: state => {
      state.leadLoading = false;
    },
    [getAddress.pending]: state => {
      state.leadAddress = {};
      state.addressLoading = true;
    },
    [getAddress.rejected]: state => {
      state.leadAddress = {};
      state.addressLoading = false;
    },
    [getAddress.fulfilled]: (state, action) => {
      state.leadAddress = action.payload || {};
      state.addressLoading = false;
    },
  },
});
export const {
  setCurrentPage,
  setLimit,
  setTotalPages,
  setPageSize,
  setLeadCreated,
  setLeadDetail,
  setTotolOfUser,
  setLeadAddress,
} = lead.actions;
export default lead.reducer;
